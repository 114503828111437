@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  /* width: 100vw; */
  /* overflow-x: hidden; */
  background-color: #ffffff;
  color: #101010;
  font-family: "Open Sans", sans-serif;
}

.dark body, html {
  /* width: 100vw; */
  /* overflow-x: hidden; */
  background-color: #101010;
  color: white;
  font-family: "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.transform-translate:hover {
  transform: translateY(-4px);
  transition: all ease 0.3s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
  }
}

.bounce-animation {
  animation: bounce 1s infinite;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromBottom 0.5s ease-out forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(100px);
    /* clip-path: inset(100% 100% 0 0); */
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    /* clip-path: inset(0 0 0 0); */
  }
}

.animation {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

@keyframes appear2 {
  from {
    opacity: 0;
    transform: translateX(-100px);
    /* clip-path: inset(100% 100% 0 0); */
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    /* clip-path: inset(0 0 0 0); */
  }
}

.animation2 {
  animation: appear2 linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

@keyframes appear3 {
  from {
    opacity: 0;
    transform: translateX(100px);
    /* clip-path: inset(100% 100% 0 0); */
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    /* clip-path: inset(0 0 0 0); */
  }
}

.animation3 {
  animation: appear3 linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.hidden1 {
  display: none;
}

@media (min-width: 550px) {
  .hidden1 {
    display: block;
  }
}

.approachHidden {
  display: none;
}

@media (min-width: 768px) {
  .approachHidden {
    display: flex;
  }
}

.aboutHidden {
  display: none;
}

@media (min-width: 1024px) {
  .aboutHidden {
    display: inline;
  }
}

@keyframes appear4 {
  from {
    opacity: 0;
    transform: translateY(100px);
    /* clip-path: inset(100% 100% 0 0); */
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    /* clip-path: inset(0 0 0 0); */
  }
}

.animation4 {
  animation: appear4 linear;
  animation-timeline: view();
  animation-range: entry 0% cover 33%;
}

.remove-mr-third:nth-child(3n) {
  margin-right: 0 !important;
}

#mobile {
  display: none;
}

.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: #af7aff;
  /* filter: drop-shadow(0rem 0rem .5rem #673AB7); */
  margin-right: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .active {
    color: #af7aff;
    margin-right: 0px;
    margin-left: 0px;
    margin-left: 20px;
    /* filter: drop-shadow(0rem 0rem .5rem #673AB7); */
  }
}

@media screen and (max-width: 768px) {
  .desktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: fixed;
    top: 80px;
    right: -300px;
    width: 15rem;
    height: 70%;
    background-color: #ffffff;
    padding: 20px 0 0 10px;
    border-left: 1px solid #cbcbcb;
    border-top: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    transition: 0.3s ease-in-out;
  }
  .dark .desktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: fixed;
    top: 80px;
    right: -300px;
    width: 15rem;
    height: 70%;
    background-color: #101010;
    padding: 20px 0 0 10px;
    border-left: 1px solid #cbcbcb;
    border-top: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    transition: 0.3s ease-in-out;
  }

  .desktop li {
    margin-bottom: 2rem;
  }

  .desktop.active {
    right: 0px;
  }

  #mobile {
    display: block;
  }

  #mobile span {
    cursor: pointer;
  }
}

/* From Uiverse.io by mobinkakei */
.loader {
  --path: #101010;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}
.dark .loader {
  --path: #d1d5db;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}
